import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import materialService from "../js/material-movement-service";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      selectedProject: "",
      projectList: [],
      runProject: false,
      runLPN: false,
      lpnSearchNum: "",
      showData: false,
      intransitLPNData: [],
      headerIntransitLPN: [
        { text: "In-Transit LPN", align: "start", value: "In-Transit LPN", class: "primary customwhite--text" },
        { text: "Quantity on LPN", value: "Quantity on LPN", class: "primary customwhite--text" },
        { text: "Linked To User", value: "Linked To User", class: "primary customwhite--text" },
        { text: "Most Recent Activity on LPN", value: "Most Receent Activity on LPN", class: "primary customwhite--text" },
        { text: "Actions", value: "Actions", class: "primary customwhite--text" },
        { text: "Details", value: "Details", class: "primary customwhite--text" },
      ],
      headerDetails: [
        { text: "Part Number", align: "start", value: "Part Number" },
        { text: "Quantity", value: "Qty" },
        { text: "BCN", value: "BCN" },
        { text: "Serial No", value: "Serial No" },
        { text: "Added To LPN", value: "Added To LPN" },
        { text: "Originated From", value: "Originated From" },
      ],
      searchLPNRule: [
        (v) => (v || "").length <= 39 || "Max Length of 39 character",
        (v) => !!v || "Field is required",
        (value) => /^[0-9]{1,3}(?:-[0-9]{3})*$/.test(value) || "Enter a correct format: 000-000-000-000",

      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getProject();
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.selectedProject = "";
      this.projectList = [];
      this.runProject = false;
      this.runLPN = false;
      this.lpnSearchNum = "";
      this.showData = false;
      this.intransitLPNData = [];
      this.getProject();
      this.$refs.projInTransitForm.resetValidation();
      this.$refs.LPNInTransitForm.resetValidation();
    },
    //Project Data
    async getProject() {
      let projData = await materialService.projectListIntransitRpt("get", this.userId);
      this.projectList = projData;
    },
    //Run Report for Project
    async projectData() {
      if (this.$refs.projInTransitForm.validate()) {
        let data = await materialService.lpnTransitRptProj("get", this.selectedProject);
        if (data.message !== "NA") {
          const newArr = data.map((obj) => {
            return {
              ...obj,
              "Most Receent Activity on LPN": Utility.convertESTToLocal(obj["Most Receent Activity on LPN"]),
            };
          });
          this.intransitLPNData = newArr;
          this.intransitLPNData = this.intransitLPNData.map((item) => ({
            ...item,
            showDetails: false,
            details: [],
          }));
          this.showData = true;
        } else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "No records based on search criteria",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.resetFunction();
        }
      }
    },
    //Run Report for LPN
    async lpnData() {
      if (this.$refs.LPNInTransitForm.validate()) {
        let data = await materialService.lpnTransitRptLPN("get", 0, this.lpnSearchNum, this.userId);
        if (data !== undefined) {
          if (data.message !== "NA") {
            const newArr = data.map((obj) => {
              return {
                ...obj,
                "Most Receent Activity on LPN": Utility.convertESTToLocal(obj["Most Receent Activity on LPN"]),
              };
            });
            this.intransitLPNData = newArr;
            this.intransitLPNData = this.intransitLPNData.map((item) => ({
              ...item,
              showDetails: false,
              details: [],
            }));
            this.showData = true;
          } else {
            let Alert = {
              type: "error",
              isShow: true,
              message: "No records based on search criteria",
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.resetFunction();
          }
        } else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "No records based on search criteria",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.resetFunction();
        }
      }
    },
    //LPN details based on click event
    async lpnDetails(item) {
      let data = await materialService.lpnTransitDrill("get", item.LPN);
      if (data.message !== "NA") {
        const newArr = data.map((obj) => {
          return {
            ...obj,
            "Added To LPN": Utility.convertESTToLocal(obj["Added To LPN"]),
          };
        });
        item.details = newArr;
        item.showDetails = !item.showDetails;
      }
    },
    //Remove LPN Details
    removeLPNDetails(item) {
      item.details = [];
      item.showDetails = !item.showDetails;
    },
    //Project Change
    projectChange() {
      this.runProject = true;
      this.lpnSearchNum = "";
      this.$refs.LPNInTransitForm.resetValidation();
    },
    //LPN value search
    lpnValidate() {
      if (this.lpnSearchNum !== null && this.lpnSearchNum !== "") {
        this.selectedProject = "";
        this.runLPN = true;
      }
    },
    //To check number with hyphen
    lpnNumFormat(event, num) {
      this.lpnSearchNum = Utility.formatLPNNumber(event, num);
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
  },
};
